import WisbotService from "@/services/WisbotService";
import AwsAiService from "@/chatpoc/services/AwsAiService";

const store = {
  namespaced: true,
  state: {
    // `chat` state
    chat: {
      isGeneratingResponse: false,
      messages: [],
      sessionIds: {},
      selectedModels: [],
      action: "retrieve_and_generate",
      adminPrompt: "",
      responseProvider: "aws",
      langchainMode: "s3",
      models: [],
      // langchainModels: [
      //   { id: "claude-3-5-sonnet-20241022", name: "anthropic" },
      //   { id: "gpt-4-1106-preview", name: "openai" },
      //   { id: "llama3.2", name: "Llama" },
      // ],
      langchainModels: [],
      awsModels: [
        {
          id: "anthropic.claude-3-haiku-20240307-v1:0",
          name: "Claude 3 Haiku",
          provider: "Anthropic",
        },
        {
          id: "anthropic.claude-3-sonnet-20240229-v1:0",
          name: "Claude 3 Sonnet",
          provider: "Anthropic",
        },
        {
          id: "meta.llama3-8b-instruct-v1:0",
          name: "Llama 3 8B Instruct",
          provider: "Llama",
        },
        {
          id: "meta.llama3-70b-instruct-v1:0",
          name: "Llama 3 70B Instruct",
          provider: "Llama",
        },
        // {
        //   id: "anthropic.claude-3-5-sonnet-20240620-v1:0",
        //   name: "Claude 3.5 Sonnet",
        //   provider: "Anthropic",
        // },
        // { id: "mistral.mixtral-8x7b-instruct-v0:1", name: "Mixtral 8x7B Instruct" },
        // { id: "amazon.titan-embed-text-v1", name: "Titan Embeddings G1 - Text" },
        // { id: "amazon.titan-embed-text-v2:0", name: "Titan Text Embeddings V2" },
      ],
      actions: [
        { value: "retrieve_and_generate", name: "Retrieve and Generate" },
        { value: "retrieve", name: "Retrieve" },
      ],
      error: null,
    },

    // `course` state
    course: {
      isFetchingDetails: false,
      error: null,
      courses: [],
      selectedCourse: "",
      shouldTriggerFetchCourses: true,
    },
  },

  mutations: {
    // `chat` mutations
    initializeModels(state) {
      state.chat.models = [...state.chat.awsModels];
    },
    setIsGeneratingResponse(state, generating) {
      state.chat.isGeneratingResponse = generating;
    },
    clearChat(state) {
      state.chat.messages = [];
    },
    addMessage(state, { prompt, responses = [], role }) {
      if (role === "user") {
        // Push a user message with empty responses
        state.chat.messages.push({ prompt, role, responses: [] });
      } else if (role === "ai") {
        // Push an AI response for the prompt
        const lastUserMessage =
          state.chat.messages[state.chat.messages.length - 1];
        if (lastUserMessage && lastUserMessage.role === "user") {
          // Append the AI responses to the last user message
          lastUserMessage.responses = responses;
        } else {
          console.error(
            "No matching user message found to attach AI responses."
          );
        }
      }
    },
    setSessionId(state, { modelId, sessionId }) {
      state.chat.sessionIds[modelId] = sessionId;
    },
    setSessionIds(state, ids) {
      state.chat.sessionIds = ids;
    },
    setAction(state, action) {
      state.chat.action = action;
    },
    setModels(state, action) {
      state.chat.models = action;
    },
    setLangchainModels(state, action) {
      state.chat.langchainModels = action;
    },
    setResponseProvider(state, action) {
      state.chat.responseProvider = action;
      state.chat.messages = [];
      state.chat.sessionIds = {};
      state.chat.selectedModels = [];
      state.chat.action = "retrieve_and_generate";
      state.chat.adminPrompt = "";
    },
    setLangchainMode(state, mode) {
      state.chat.langchainMode = mode; // Update selected models
      state.chat.messages = [];
      state.chat.sessionIds = {};
      state.chat.selectedModels = [];
      state.chat.action = "retrieve_and_generate";
      state.chat.adminPrompt = "";
    },
    setSelectedModels(state, selectedModels) {
      state.chat.selectedModels = selectedModels; // Update selected models
    },
    setError(state, error) {
      state.chat.error = error;
    },
    setAdminPrompt(state, prompt) {
      state.chat.adminPrompt = prompt;
    },

    // `course` mutations
    setIsFetchingDetails(state, loading) {
      state.course.isFetchingDetails = loading;
    },
    setCourses(state, courses) {
      state.course.courses = courses;
    },
    addCourse(state, course) {
      state.course.courses.push({ value: course, name: course });
    },
    setCourse(state, course) {
      state.course.selectedCourse = course;
    },
    setShouldTriggerFetchCourses(state, value) {
      state.shouldTriggerFetchCourses = value;
    },
  },

  actions: {
    // `chat` actions
    initializeStore({ commit }) {
      commit("initializeModels");
    },
    setIsGeneratingResponse({ commit }, generating) {
      commit("setIsGeneratingResponse", generating);
    },
    clearChat({ commit }) {
      commit("clearChat");
    },
    addMessage({ commit }, { message, role }) {
      commit("addMessage", { message, role });
    },
    setSessionId({ commit }, id) {
      // Validate the session ID if necessary
      if (id && typeof id === "string") {
        commit("setSessionId", id);
      } else {
        console.error("Invalid session ID:", id);
      }
    },
    setAction({ commit }, action) {
      commit("setAction", action);
    },
    setModels({ commit }, action) {
      commit("setModels", action);
    },
    setLangchainModels({ commit }, action) {
      commit("setLangchainModels", action);
    },
    setResponseProvider({ commit }, action) {
      commit("setResponseProvider", action);
    },
    setLangchainMode({ commit }, mode) {
      commit("setLangchainMode", mode);
    },
    setSelectedModels({ commit }, models) {
      commit("setSelectedModels", models);
    },
    setError({ commit }, error) {
      commit("setError", error);
    },
    setIsFetchingDetails({commit}, loading) {
      commit("setIsFetchingDetails", loading);

    },
    setShouldTriggerFetchCourses({ commit }, shouldTriggerFetchCourses) {
      commit("setShouldTriggerFetchCourses", shouldTriggerFetchCourses);
    },
    async fetchLangchainModels({ commit }) {
      commit("setIsFetchingDetails", true);
      try {
        // Fetch dynamic LangChain (Ollama) models from the backend (via WisbotService)
        const res = await WisbotService.getAllModels();
        commit("setLangchainModels", res.data.models);
        commit("setModels", res.data.models);

        return res.data.models
      } catch (error) {
        console.error("Error fetching LangChain models:", error.message);
        commit("setError", error.message);
      }
    },
    async generateResponses({ commit, state }, prompt) {
      // Add the user message first
      commit("addMessage", { prompt, role: "user" });

      commit("setIsGeneratingResponse", true);
      try {
        const responses = await Promise.all(
          state.chat.selectedModels.map(async (model) => {
            let requestBody;
            if (state.chat.responseProvider === "aws") {
              requestBody = {
                model,
                prompt,
                action: state.chat.action,
                course: state.course.selectedCourse,
                adminPrompt: state.chat.adminPrompt,
              };
            } else {
              requestBody = {
                model,
                question: prompt,
                course: state.course.selectedCourse,
              };
            }
            const modelSessionId = state.chat.sessionIds[model.id];
            if (modelSessionId) {
              requestBody.sessionId = modelSessionId;
            }
            let res;
            if (state.chat.responseProvider === "aws") {
              res = await AwsAiService.getAiResponse(requestBody);
            } else {
              res = await WisbotService.getAiResponse(requestBody);
            }
            let awsData;
            let responseMessage;

            if (state.chat.responseProvider === "aws") {
              awsData = res.data.body;
              if (res.data.statusCode !== 200) {
                responseMessage = awsData.generated_text;
              } else if (state.chat.action === "retrieve") {
                responseMessage = awsData.texts.flat().join("\n\n");
              } else if (state.chat.action === "retrieve_and_generate") {
                responseMessage = awsData.generated_text;
              } else {
                responseMessage = "Error in generating response.";
              }
            } else {
              responseMessage = res.data.answer;
            }
            if (res.data.error) {
              responseMessage =
                "Sorry, I encountered an error. Please try again.";
              throw new Error(res.data.error);
            }
            if (state.chat.responseProvider === "aws") {
              return {
                model,
                message: responseMessage,
                sessionId: awsData.session_id,
                resources: awsData.resources,
              };
            } else {
              return {
                model,
                message: responseMessage,
                resources: [],
              };
            }
          })
        );
        // Add the AI responses to the corresponding user message
        const aiResponses = responses.map(
          ({ model, message, resources }) => ({
            model,
            message,
            resources,
          })
        );
        commit("addMessage", { responses: aiResponses, role: "ai" });

        // Store the session IDs for each model
        responses.forEach(({ model, sessionId }) => {
          if (sessionId) commit("setSessionId", { modelId: model.id, sessionId });
        });
      } catch (error) {
        commit("setError", error.message || "An error occurred");
      } finally {
        commit("setIsGeneratingResponse", false);
      }
    },

    // `course` actions
    async fetchCourses({ commit, state }) {
      try {
        commit("setIsFetchingDetails", true);
        let response, data;

        if (state.chat.langchainMode === "s3") {
          response = await AwsAiService.getAllFolders();
          data = response.data?.body;
        } else {
          response = await WisbotService.getAllCourses();
          data = response.data;
        }

        if (response.data?.error) {
          commit("setError", response.data.error.message);
          return;
        }

        const courses = data?.folders.map((item) => ({
          value: item,
          name: item,
        }));

        commit("setCourses", courses);
        commit("setCourse", courses[0]?.value || "");

        return courses;
      } catch (error) {
        commit("setError", error.message);
      }
    },
  },
};

store.mutations.initializeModels(store.state);

export default store;
