//Pragya Client App
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store.js";
import { createI18n } from "vue-i18n";
import messages from "./common/messages.json";
import PrimeVue from "primevue/config";
import Lara from '@primevue/themes/lara';
import ToastService from "primevue/toastservice";

// For Global CSS
import "./common/global.css";

// PrimeVue icons and CSS
import "primeicons/primeicons.css";
import Toast from "primevue/toast";

const i18n = createI18n({
  locale: "en", // Default locale
  fallbackLocale: "en", // Fallback locale
  messages: {
    en: messages, // Messages imported from messages.json
  }, // Messages imported from messages.json
});

// Global navigation guard
// Global navigation guard
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  const publicRoutes = ['login', 'sharedchat'];
  
  // Handle login page access
  if (publicRoutes.includes(to.name)) {
    next();
    return;
  }

  // Handle unauthorized access
  if (!token) {
    next({ 
      name: "login", 
      query: { redirect: to.path } 
    });
    return;
  }

  // Handle mainlayout redirect
  if (to.name === "mainlayout" && token) {
    next({ name: "getallcoursemc" });
    return;
  }

  // Handle breadcrumb clearing for specific routes
  const breadcrumbClearRoutes = [
    '/getallcoursemc',
    '/getcourselist',
    '/login',
    '/logout',
    '/showallprompts',
    '/getprecannedquestions',
    '/getprecannedrails',
    '/aide',
    '/sendeEmail',
    '/kcexplanation'
  ];

  if (from.name && breadcrumbClearRoutes.includes(to.path)) {
    store.commit("clearBreadcrumbs");
  }

  // Handle breadcrumb addition
  if (to.meta.breadcrumb) {
    store.commit("addBreadcrumb", {
      to: to.path,
      query: to.query,
      label: to.meta.breadcrumb(to),
    });
  }

  // Proceed with navigation
  next();
});

const app = createApp(App);

// Configure PrimeVue and its services
app.use(PrimeVue, {
  theme: {
      preset: Lara,
      options: {
          prefix: 'p',
          darkModeSelector: '.dark',
          cssLayer: 'app-styles, tailwind, primevue'
      }
  }
});
app.use(ToastService);

// Register PrimeVue components globally
app.component("Toast", Toast);

// Mount the application with all other plugins
app.use(router).use(store).use(i18n).mount("#app");
