import axios from "axios";
import ToastService from "./services/ToastService";

//comment below line if you are running on cloud
//let PRAGYA_SERVER_PATH='http://localhost:8080/api/';

//comment below line if you are running locall
let PRAGYA_SERVER_PATH='/api';

const instance = axios.create({
  baseURL: PRAGYA_SERVER_PATH,
  headers: {
    "Content-type": "application/json"
  },
  withCredentials: true
});

// Add an interceptor to include the JWT token in the request headers
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    // If the response is successful, return it directly
    return response;
  },
  (error) => {
    // If the response status is 401 (Unauthorized), handle it
    if (error.response?.status === 401) {
      // Show a message to the user
      ToastService.showToast('error', 'Unauthorized access', 'Your Session may have timed out. Please log in again.' );

      // Redirect the user to the login page
      window.location.href = '#/login';
    }
    // Return the error to continue error handling in the calling component
    return Promise.reject(error);
  }
);

export default instance;
