import { useToast } from "primevue/usetoast";

class ToastService {
  constructor() {
    this.toast = null;
  }

  // Initialize the toast instance
  initToast() {
    this.toast = useToast();
  }

  /**
   * Method to show a toast
   * @param {string} severity - Severity of the toast (e.g., 'success', 'error', 'info', 'warn').
   * @param {string} summary - Summary text of the toast (ignored for templates).
   * @param {string} detail - Detail text of the toast (ignored for templates).
   * @param {number} life - Duration of the toast in milliseconds (default: 3000).
   */
  showToast(severity, summary, detail, life = 3000) {
    if (!this.toast) {
      console.error(
        "Toast instance is not initialized. Call `initToast()` first."
      );
      return;
    }

    const toastOptions = {
      severity,
      life,
      summary,
      detail,
    };

    this.toast.add(toastOptions);
  }
}

// Create and export a singleton instance
export default new ToastService();
