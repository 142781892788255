import axios from 'axios'

const api = axios.create({
    baseURL: process.env.VUE_APP_AI_API_BASE_URL + process.env.VUE_APP_AI_API_STAGE
})

// Response interceptor to handle API Gateway responses
api.interceptors.response.use(
    (response) => {
        // Handle the nested body structure from API Gateway
        const parsedData = typeof response.data === 'string'
            ? JSON.parse(response.data)
            : response.data;
        response.data = parsedData;
        if(parsedData.success){
            const parsedBody = typeof parsedData.body === 'string'
                ? JSON.parse(parsedData.body)
                : parsedData.body
            response.data.body = parsedBody;
        }
            
        // Check if it's an error response
        if (!parsedData?.success) {
            throw new Error(parsedData.error.message);
        }

        return response;
    },
    (error) => {
        console.error('Error in interceptor:', error); // Debug log
        
        // Try to parse error response if it exists
        if (error.response?.data?.body) {
            try {
                const errorBody = typeof error.response.data.body === 'string'
                    ? JSON.parse(error.response.data.body)
                    : error.response.data.body;
                    
                throw new Error(errorBody.error?.message || 'An error occurred');
            } catch (parseError) {
                console.error('Error parsing error response:', parseError);
            }
        }
        
        // Fallback error message
        throw new Error(error.message || 'An error occurred');
    }
);

export default api