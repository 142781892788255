import http from "../http-common";

class WisbotService {
  getAiResponse(data) {
    return http.post("/ai/ai_response", data);
  }
  getAiContentYoutube(data) {
    return http.post("/ai/get_ai_content/youtube", data);
  }
  getAiContentS3(data) {
    return http.post("/ai/get_ai_content/s3", data);
  }
  deleteAiContent(data) {
    return http.post("/ai/delete_ai_content", data);
  }
  getAllCourses() {
    return http.get("/ai/get_courses");
  }
  getAllModels() {
    return http.get("/ai/get_models");
  }
  generateCourse(data) {
    return http.post("/ai/generate_course", data);
  }
  generateYoutubeLinks(data){
    return http.post("/ai/youtube", data);
  }
}

export default new WisbotService();
