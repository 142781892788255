import api from "../api/api";


class AwsAiService {
  getAiResponse(data) {
    return api.post("/ai_response", data);
  }
  getAllFolders() {
    return api.get("/s3/get_all_folders");
  }
  getAllFiles(param) {
    return api.get(`/s3/get_all_files?path=${param}`);
  }
  createNewFolder(data) {
    return api.post("/s3/create_new_folder", data);
  }
  uploadFiles(data) {
    return api.post("/s3/upload_files", data);
  }
  uploadFolder(data) {
    return api.post("/s3/upload_folder", data);
  }
  generatePresignedURL(param) {
    return api.get(`/s3/generate_presigned_url?key=${param}`);
  }
  shareChat(data) {
    return api.post("/chat", data);
  }
  retrieveChat(param) {
    return api.get(`/chat?chat_id=${param}`);
  }
  generateYoutubeLinks(data){
    return api.post("/youtube/generate", data);
  }
}

export default new AwsAiService();
