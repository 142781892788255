import Vuex from 'vuex'
import { mapState } from 'vuex';
import chatpoc from '../chatpoc/stores/store'

const store = new Vuex.Store({
    modules: {
        chatpoc,
      },
    state: {
        breadcrumbHistory: JSON.parse(localStorage.getItem('breadcrumbHistory')) || [],
        token: localStorage.getItem('token') || "",
        courseId: localStorage.getItem('courseId') || "",
        courseData: JSON.parse(localStorage.getItem('courseData')) || null,
        contentId: localStorage.getItem('contentId') || "",
        contentBody: localStorage.getItem('contentBody') || "",
        userId: localStorage.getItem('userId') || "",
        userInstitutionId: localStorage.getItem('userInstitutionId') || "",
        assessmentId: localStorage.getItem('assessmentId') || "",
            courseTitle: localStorage.getItem('courseTitle') || "",
            courseToggle: localStorage.getItem('courseToggle') || "Selected", // Add courseToggle state with default value "Selected"
            kcPrompt: localStorage.getItem('kcPrompt') || "",
            mcPrompt: localStorage.getItem('mcPrompt') || "",
            cPrompt: localStorage.getItem('cPrompt') || "",
            kcUserPrompt: localStorage.getItem('kcUserPrompt') || "",
            firstName: localStorage.getItem('firstName') || "" ,
            assessmentData: JSON.parse(localStorage.getItem('assessmentData')) || null,
            userEnrolledCourses: JSON.parse(localStorage.getItem('userEnrolledCourses')) || [], // Add userEnrolledCourses state with default value []        
            institutionLogo: localStorage.getItem('institutionLogo') || "", // Add institutionLogo state with default value ""
            selectedEntityName: localStorage.getItem('selectedEntityName') || "", // Add selectedEntityName state
            kcActiveTab: localStorage.getItem('kcActiveTab') || "kcExplanation", // Add kcActiveTab state with default value "kc"
            preCannedQuestionType: localStorage.getItem('preCannedQuestionType') || "", // Add setPreCannedQuestionType state with default value ""
            preCannedQuestions: JSON.parse(localStorage.getItem('preCannedQuestions')) || null, // Add preCannedQuestions state with default value []
            preCannedRails: JSON.parse(localStorage.getItem('preCannedRails')) || null, // Add preCannedRails state with default value []
            courseDataResources: JSON.parse(localStorage.getItem('courseDataResources')) || null
        },
        mutations: {
            addBreadcrumb(state, breadcrumb) {
                const fullPath = breadcrumb.query ? 
                    `${breadcrumb.to}?${new URLSearchParams(breadcrumb.query).toString()}` : 
                    breadcrumb.to;
                    
                const newBreadcrumb = {
                    ...breadcrumb,
                    to: fullPath
                };
        
                const index = state.breadcrumbHistory.findIndex(b => b.to === fullPath);
                
                if (index !== -1) {
                    state.breadcrumbHistory = state.breadcrumbHistory.slice(0, index + 1);
                } else {
                    state.breadcrumbHistory.push(newBreadcrumb);
                }
                
                // Save to localStorage
                localStorage.setItem('breadcrumbHistory', JSON.stringify(state.breadcrumbHistory));
            },
            clearBreadcrumbs(state) {
                state.breadcrumbHistory = [];
                localStorage.removeItem('breadcrumbHistory');
            },
            setToken(state, token) {
                state.token = token;
                localStorage.setItem('token', token);
            },
            setCourseData(state, data) {
                state.courseData = data;
                localStorage.setItem('courseData', JSON.stringify(data));
            },
            setCourseId(state, id) {
                state.courseId = id;
                localStorage.setItem('courseId', id);
            },
            setContentId(state, id) {
                state.contentId = id;
                localStorage.setItem('contentId', id);
            },
            setContentBody(state, body) {
                state.contentBody = body;
                localStorage.setItem('contentBody', body);
            },
            setUserId(state, id) {
                state.userId = id;
                localStorage.setItem('userId', id);
            },
            setUserInstitutionId(state, id) {
                state.userInstitutionId = id;
                localStorage.setItem('userInstitutionId', id);
            },
            setAssessmentId(state, id) {
                state.assessmentId = id;
                localStorage.setItem('assessmentId', id);
            },
            setCourseTitle(state, title) {
                state.courseTitle = title;
                localStorage.setItem('courseTitle', title);
            },
            setKCPrompt(state, prompt) {
                state.kcPrompt = prompt;
                localStorage.setItem('kcPrompt', prompt);
            },
            setMCPrompt(state, prompt) {
                state.mcPrompt = prompt;
                localStorage.setItem('mcPrompt', prompt);
            },
            setCPrompt(state, prompt) {
                state.cPrompt = prompt;
                localStorage.setItem('cPrompt', prompt);
            },
            setKCUserPrompt(state, prompt) {
                state.kcUserPrompt = prompt;
                localStorage.setItem('kcUserPrompt', prompt);
            },
            setFirstName(state, firstName) { // Add setFirstName mutation
                state.firstName = firstName;
                localStorage.setItem('firstName', firstName);
            },
            setAssessmentData(state, data) {
                state.assessmentData = data;
                localStorage.setItem('assessmentData', JSON.stringify(data));
            },
            toggleCourseState(state) {
                state.courseToggle = state.courseToggle === "All" ? "Selected" : "All";
                localStorage.setItem('courseToggle', state.courseToggle);
                console.log(state.courseToggle);
            },
            setUserEnrolledCourses(state, courses) { // Add setUserEnrolledCourses mutation
                state.userEnrolledCourses = courses;
                localStorage.setItem('userEnrolledCourses', JSON.stringify(courses));
            },
            setInstitutionLogo(state, logo) { // Add setInstitutionLogo mutation
                state.institutionLogo = logo;
                localStorage.setItem('institutionLogo', logo);
            },
            setSelectedEntityName(state, name) { // Add setSelectedEntityName mutation
                state.selectedEntityName = name;
                localStorage.setItem('selectedEntityName', name);
            },
            setKCActiveTab(state, tab) {
                state.kcActiveTab = tab;
                localStorage.setItem('kcActiveTab', tab);
            },
            setPreCannedQuestionType(state, type) { // Add setPreCannedQuestionType mutation
                state.preCannedQuestionType = type;
                localStorage.setItem('preCannedQuestionType', type);
            },
            setPreCannedQuestions(state, questions) { // Add setPreCannedQuestions mutation
                state.preCannedQuestions = questions;
                localStorage.setItem('preCannedQuestions', JSON.stringify(questions));
            },
            setPreCannedRails(state, rails) { // Add setPreCannedRails mutation
                state.preCannedRails = rails;
                localStorage.setItem('preCannedRails', JSON.stringify(rails));
            },
            setCourseDataResources(state, { courseId, courseData }) {
                state.courseDataResources = { courseId, courseData };
                localStorage.setItem('courseDataResources', JSON.stringify(state.courseDataResources));
            }
        },
        getters: {
            getCourseId(state) {
                return state.courseId;
            },
            getCourseData(state) {
                return state.courseData;
            },
            getContentId(state) {
                return state.contentId;
            },
            getContentBody(state) {
                return state.contentBody;
            },
            getUserId(state) {
                return state.userId;
            },
            getUserInstitutionId(state) {
                return state.userInstitutionId;
            },
            getSelectedEntityName(state) { // Add getSelectedEntityName getter
                return state.selectedEntityName;
            },
            getAssessmentId(state) {
                return state.assessmentId;
            },
            getCourseTitle(state) {
                return state.courseTitle;
            },
            getKCPrompt(state) {
                return state.kcPrompt;
            },
            getMCPrompt(state) {
                return state.mcPrompt;
            },
            getCPrompt(state) {
                return state.cPrompt;
            },
            getKCUserPrompt(state) {
                return state.kcUserPrompt;
            },
            getFirstName(state) { // Add getFirstName getter
                return state.firstName;
            },
            getAssessmentData(state) {
                return state.assessmentData;
            },
            getCourseToggle(state) { // Add getCourseToggle getter
                return state.courseToggle;
            },
            getUserEnrolledCourses(state) { // Add getUserEnrolledCourses getter
                return state.userEnrolledCourses;
            },
            getInstitutionLogo(state) { // Add getInstitutionLogo getter
                return state.institutionLogo;
            },
            getKCActiveTab(state) {
                return state.kcActiveTab;
            },
            getPreCannedQuestionType(state) { // Add getPreCannedQuestionType getter
                return state.preCannedQuestionType;
            },
            getPreCannedQuestions(state) { // Add getPreCannedQuestions getter
                return state.preCannedQuestions;
            },
            getPreCannedRails(state) { // Add getPreCannedRails getter
                return state.preCannedRails;
            },
            getCourseDataResources(state) {
                return state.courseDataResources;
            }
    }
});

mapState({
    courseData: state => state.courseData,
    courseId: state => state.courseId,
    contentId: state => state.contentId,
    contentBody: state => state.contentBody,
    courseTitle: state => state.courseTitle,
    userId: state => state.userId,
    userInstitutionId: state => state.userInstitutionId,
    assessmentId: state => state.assessmentId,
    kcPrompt: state => state.kcPrompt,
    mcPrompt: state => state.mcPrompt,
    cPrompt: state => state.cPrompt,
    kcUserPrompt: state => state.kcUserPrompt,
    firstName: state => state.firstName, // Add firstName property
    assessmentData: state => state.assessmentData,
    courseToggle: state => state.courseToggle, // Add courseToggle property
    userEnrolledCourses: state => state.userEnrolledCourses, // Add userEnrolledCourses property
    institutionLogo: state => state.institutionLogo, // Add institutionLogo property
    selectedEntityName: state => state.selectedEntityName, // Add selectedEntityName property
    kcActiveTab: state => state.kcActiveTab,
    preCannedQuestionType: state => state.preCannedQuestionType, // Add preCannedQuestionType property
    preCannedQuestions: state => state.preCannedQuestions, // Add preCannedQuestions property
    preCannedRails: state => state.preCannedRails, // Add preCannedRails property
    courseDataResources: state => state.courseDataResources // Add preCannedRails property
}); 

export default store;