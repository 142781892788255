import { createRouter, createWebHashHistory } from "vue-router";
import store from "./store/store";
const routes = [
  {
    path: "/sharedchat/:chatId",
    name: "sharedchat",
    component: () => import("./chatpoc/views/SharedChatView.vue"),
  },

  {
    path: "/",
    name: "mainlayout",
    component: () => import("./MainLayout.vue"),
    children: [
      //MC
      {
        path: "getallcoursemc",
        name: "getallcoursemc",
        component: () => import("./components/learningspaces/GetAllCourseMC"),
        meta: {
          breadcrumb: () => "Courses - Competencies",
        },
      },
      {
        path: "resources",
        name: "getallresources",
        component: () => import("./components/learningspaces/GetAllCourseResources"),
        meta: {
          breadcrumb: () => "Courses - Resources",
        },
      },
      {
        path: "managemcs",
        name: "managemcs",
        component: () => import("./components/learningspaces/ManageMCs"),
        meta: {
          breadcrumb: () => store.state.courseTitle || "Course Details",
        },
      },
      //KCs
      {
        path: "getcourselist",
        name: "getcourselist",
        component: () => import("./components/learningspaces/GetAllCourseKC"),
        meta: {
          breadcrumb: () => "Courses - Knowledge Checks",
        },
      },
      {
        path: "managekcs",
        name: "managekcs",
        component: () => import("./components/learningspaces/ManageKCs"),
        meta: {
          breadcrumb: () =>
            store.state.courseTitle + " - " + store.state.firstName ||
            "Course Details",
        },
      },
      {
        path: "assessmentlist",
        name: "assessmentlist",
        component: () => import("./components/assessment/AssessmentList"),
        meta: {
          breadcrumb: () => "Assessments",
        },
      },
      //prompts
      // {
      //   path: "/getcourselistforprompts",
      //   name: "getcourselistforprompts",
      //   component: () => import("./components/prompts/GetAllPrompts")
      // },
      {
        path: "showallprompts",
        name: "showallprompts",
        component: () => import("./components/prompts/ShowAllPrompts"),
        meta: {
          breadcrumb: () => "Prompts",
        },
      },
      //questions
      {
        path: "getprecannedquestions",
        name: "getprecannedquestions",
        component: () => import("./components/questions/GetPreCannedQuestions"),
        meta: {
          breadcrumb: () => "Questions",
        },
      },
      //guardrails
      {
        path: "getprecannedrails",
        name: "getprecannedrails",
        component: () => import("./components/guardrails/ManageGR.vue"),
        meta: {
          breadcrumb: () => "Guardrails",
        },
      },
      //chat
      {
        path: "qna",
        name: "qna",
        component: () => import("./components/chat/QnAComponent"),
        meta: {
          breadcrumb: () => "ChatBot",
        },
      },
      //editor
      {
        path: "editor/:contentId",
        name: "editorcomponent",
        component: () => import("./components/learningspaces/EditorComponent"),
        props: true,
        meta: {
          breadcrumb: () => {
            const prevPath =
              store.state.breadcrumbHistory[
                store.state.breadcrumbHistory.length - 1
              ]?.to;

            if (prevPath && prevPath.includes("managekcs")) {
              const tabName =
                store.state.kcActiveTab === "kcExplanation"
                  ? "KC Explanation"
                  : "Answer Analysis";
              return `${
                store.state.selectedEntityName || "AI Content"
              } - ${tabName}`;
            }
            return (
              "AI Content" +
              (store.state.selectedEntityName
                ? " - " + store.state.selectedEntityName
                : "")
            );
          },
        },
      },
      //login
      {
        path: "login",
        name: "login",
        component: () => import("./components/auth/LoginUser"),
        meta: {
          breadcrumb: () => "Login",
        },
      },
      {
        path: "logout",
        name: "logout",
        component: () => import("./components/auth/LogoutUser"),
        meta: {
          breadcrumb: () => "Logout",
        },
      },
      //archived content
      {
        path: "archivedcontent/:courseId/:entityId",
        name: "archivedcontent",
        component: () => import("./components/learningspaces/ArchivedContent"),
        props: true,
        meta: {
          breadcrumb: () =>
            store.state.selectedEntityName
              ? "Archive - " + store.state.selectedEntityName
              : "Archived Content",
        },
      },
      //Email testing
      {
        path: "sendeEmail",
        name: "sendeemail",
        component: () => import("./components/mail/EMail"),
        meta: {
          breadcrumb: () => "Email Testing",
        },
      },
      //Author Aide
      {
        path: "/aide",
        name: "aide",
        component: () => import("./components/author/AuthorsAide"),
        meta: {
          breadcrumb: () => "Author Aide",
        },
      },
      //kcexplanation
      {
        path: "/kcexplanation",
        name: "kcexplanation",
        component: () =>
          import("./components/learningspaces/GetAllKCExplanation"),
        meta: {
          breadcrumb: () => "KC Explanation",
        },
      },
      {
        path: "chatpoc",
        name: "chatpoc",
        component: () => import("./chatpoc/layouts/Layout"),
        meta: {
          breadcrumb: () => "Chat POC",
        },
        children: [
          {
            path: "",
            name: "chatpoc_landing",
            component: () => import("./chatpoc/views/LandingPageView"),
          },
          {
            path: "chat",
            name: "chatpoc_chat",
            component: () => import("./chatpoc/views/ChatView"),
          },
          {
            path: "upload",
            name: "chatpoc_upload",
            component: () => import("./chatpoc/views/CoursesView"),
          },
          {
            path: "course-list/:pathMatch(.*)*",
            name: "chatpoc_courselist",
            component: () => import("./chatpoc/views/CourseListView"),
          },
          {
            path: "youtube",
            name: "chatpoc_youtube",
            component: () => import("./chatpoc/views/YoutubeVideosView"),
          },
          {
            path: "generate_course",
            name: "chatpoc_generate_course",
            component: () => import("./chatpoc/views/GenerateCourseView"),
          },
        ],
      },
      // {
      //   path: "/pdf",
      //   name: "pdf_viewer",
      //   component: () => import("./components/pdf/PDF"),
      // },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
